import { get } from './api';
export default {
  data() {
    return {
      urls: ['river', 'dialect', 'interact'],
      config: {},
      INAPP: window.menu,
      assetsUrl: window.assetsUrl,
      actions: [{
        type: 'refresh',
        name: '刷新'
      }, {
        type: 'min',
        name: '窗口最小化'
      }, {
        type: 'close',
        name: '退出系统'
      }],
      show: false,
      width: '',
      height: ''
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.getWindowInfo();
    });
    this.getWindowInfo();
    this.getHome();
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.getWindowInfo();
    });
  },
  methods: {
    getWindowInfo() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    showMenu() {
      this.show = true;
    },
    onSelect(item) {
      this.show = false;
      setTimeout(() => {
        this.processAction(item.type);
      }, 500);
    },
    async processAction(type) {
      switch (type) {
        case 'min':
          await window.menu.min();
          break;
        case 'max':
          await window.menu.max();
          break;
        case 'close':
          await window.menu.close();
          break;
        case 'refresh':
          await window.menu.refresh();
          break;
      }
    },
    getHome() {
      get('home').then(res => {
        console.log(res);
        document.title = res.data.site_title;
        this.config = res.data;
      });
    }
  }
};