import axios from 'axios';
import { showToast } from 'vant';

axios.defaults.baseURL = window.location.hostname == 'localhost'?'http://127.0.0.1/api/':'https://river.wxlion.com/api/';
// axios.defaults.headers.common['X-API-KEY'] = '';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const tokenName = 'x-api-token';

// 创建axios实例
const instance = axios.create({
    timeout: 10000, // 请求超时时间
  });
   
  // 请求拦截器
  instance.interceptors.request.use(
    config => {
      // 在请求发送之前可以做一些处理，比如添加请求头等
      let token = localStorage.getItem(tokenName);
      if (token) {
        // config.headers["X-API-KEY"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
      }
      return config;
    },
    error => {
      // 请求错误处理
      return Promise.reject(error);
    }
  );
   
  // 响应拦截器
  instance.interceptors.response.use(
    response => {
      // 在这里可以对响应数据进行处理
      return response.data;
    },
    error => {
      // 响应错误处理
      if(error.code == 'ERR_NETWORK'){
        showToast('本地识别服务未开启!' + error.message)
      }else if(error.response.status == 401){
        //
      }
      return Promise.reject(error);
    }
  );
  
  export function setToken(value){
    localStorage.setItem(tokenName,value);
  }
  // 封装get请求
  export function get(url, params) {
    return instance.get(url, {
      params,
    });
  }
   
  // 封装post请求
  export function post(url, data) {
    return instance.post(url, data);
  }


  export function getDistance(lat1, lng1, lat2, lng2) {
    lat1 = lat1 || 0;
    lng1 = lng1 || 0;
    lat2 = lat2 || 0;
    lng2 = lng2 || 0;

    var rad1 = lat1 * Math.PI / 180.0;
    var rad2 = lat2 * Math.PI / 180.0;
    var a = rad1 - rad2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var r = 6378137;
    var distance = r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)));

    return parseInt(distance);
}


export function doLogin(){
  if(window.location.hostname == 'localhost'){
    setToken('4wxlion6417fd86ae877');
  }else{
    window.location.href="/index/auth?refer=/h5/";
  }
}


export function getUrlParams(url) {
    // 通过 ? 分割获取后面的参数字符串
  if(url.indexOf('#') > 0){
    url = url.split('#')[0];
  }
  if(url.indexOf('?') == -1){
    return {};
  }
  let urlStr = url.split('?')[1]
    // 创建空对象存储参数
	let obj = {};
    // 再通过 & 将每一个参数单独分割出来
	let paramsArr = urlStr.split('&')
	for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
		let arr = paramsArr[i].split('=')
		obj[arr[0]] = arr[1];
	}
	return obj
}

