import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('./pages/HomePage.vue'),
    meta: {
      title: '运河',
    },
  },
  {
    name: 'river',
    path: '/river',
    component: () => import('./pages/RiverPage.vue'),
    meta: {
      title: '运河',
    },
  },
  {
    name: 'dialect',
    path: '/dialect',
    component: () => import('./pages/DialectPage.vue'),
    meta: {
      title: '方言',
    },
  },
  {
    name: 'interact',
    path: '/interact',
    component: () => import('./pages/InteractPage.vue'),
    meta: {
      title: '互动',
    },
  },
  {
    name: 'ask',
    path: '/ask',
    component: () => import('./pages/AskPage.vue'),
    meta: {
      title: '互动',
    },
  },
  {
    name: 'puzzle',
    path: '/puzzle',
    component: () => import('./pages/PuzzlePage.vue'),
    meta: {
      title: '互动',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
