import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_action_sheet = _resolveComponent("van-action-sheet");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    id: "app-home",
    style: _normalizeStyle({
      background: 'url(\'' + $data.config.site_background || './assets/home.png' + '\')',
      width: $data.width + 'px',
      height: $data.height + 'px'
    })
  }, [$data.INAPP ? (_openBlock(), _createBlock(_component_van_icon, {
    key: 0,
    name: "apps-o",
    style: {
      "position": "fixed",
      "z-index": "666",
      "top": "25px",
      "right": "20px",
      "font-size": "1.8rem",
      "color": "white"
    },
    onClick: $options.showMenu
  }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_van_action_sheet, {
    show: $data.show,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $data.show = $event),
    actions: $data.actions,
    onSelect: $options.onSelect
  }, null, 8, ["show", "actions", "onSelect"]), _createVNode(_component_router_view)], 4)]);
}