import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import 'animate.css';
import 'vant/es/toast/style';
import 'vant/es/notify/style' 
// 确认框同理，其它不显示的样式同理引入相关css即可
import 'vant/es/dialog/style'

const app = createApp(App);
app.use(router);
app.mount('#app');
